import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";
import { frFR as dataGridfrFR } from '@mui/x-data-grid';
import { frFR as corefrFR } from '@mui/material/locale';
import { frFR } from '@mui/x-date-pickers/locales';

const createTheme = (name) => {
  let themeConfig = variants[1];

  return createMuiTheme(
    {
      frFR, // x-date-pickers translations
      dataGridfrFR, // x-data-grid translations
      corefrFR,
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
      frFR, // x-date-pickers translations
      dataGridfrFR, // x-data-grid translations
      corefrFR,
    }
  );

};

export default createTheme;
