import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome back to Linar": "Welcome back to Linar",
      "Sales Month": "Sales Month",
      "Month": "Month",
      "Total Earnings": "Total Earnings",
      "Pending Orders": "Pending Orders",
      "Total Revenue": "Total Revenue",
      "New Messages": "New Messages",
      "Show all messages": "Show all messages",
      "New Notifications": "New Notifications",
      "Show all notifications": "Show all notifications",
      "French": "French",
      "English": "English",
      "Languages": "Languages",
      "Account": "Account",
      "Profile": "Profile",
      "Sign out": "Sign out",
      "List of Material": "List of Material",
      "List of Article": "List of Article",
      "List of Customer": "List of Customer",

    },
  },
  fr: {
    translation: {
      "Welcome back to Linar": "Bon retour sur Linar",
      "Sales Month": "Vente du Mois",
      "Month": "Mois",
      "Total Earnings": "Revenu Total",
      "Pending Orders": "Commandes en attente",
      "Total Revenue": "Revenu Total",
      "New Messages": "Nouveaux Messages",
      "Show all messages": "Voir tous les messages",
      "New Notifications": "Nouvelles Notifications",
      "Show all notifications": "Voir toutes les notifications",
      "French": "Français",
      "English": "Anglais",
      "Languages": "Langues",
      "Account": "Compte",
      "Profile": "Profil",
      "Sign out": "Déconnexion",
      "List of Material": "Liste des Matières",
      "List of Article": "Liste des Articles",
      "List of Customer": "Liste des Clients",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
